import React from 'react';
import ImageGallery from 'react-image-gallery';

export default function ImageGalleryContainer(props) {
  return (
    <div className="image-gallery-container">
      <ImageGallery items={props.images}/>
    </div>
  )
}
