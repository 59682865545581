import React, {Component} from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-image-gallery/styles/css/image-gallery.css";

import ImageGalleryContainer from './components/ImageGalleryContainer';

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      user: '',
      images: [
        {
          original: './images/OceanBreakers.jpg',
          thumbnail: './images/OceanBreakers.thumb.jpg'
        },
        {
          original: './images/DutchMan.jpg',
          thumbnail: './images/DutchMan.thumb.jpg'
        },
        {
          original: './images/BirdOfParadise.jpg',
          thumbnail: './images/BirdOfParadise.jpg',
        },
        {
          original: './images/BlueStillLife.jpg',
          thumbnail: './images/BlueStillLife.jpg',
        },
        {
          original: './images/Bob.jpg',
          thumbnail: './images/Bob.jpg',
        },
        {
          original: './images/ChineseWoman.jpg',
          thumbnail: './images/ChineseWoman.jpg',
        },
        {
          original: './images/FlowersLake.jpg',
          thumbnail: './images/FlowersLake.jpg',
        },
        {
          original: './images/GoldenEaring.jpg',
          thumbnail: './images/GoldenEaring.jpg',
        },
        {
          original: './images/GoldPeppers.jpg',
          thumbnail: './images/GoldPeppers.jpg',
        },
        {
          original: './images/LilyBridge.jpg',
          thumbnail: './images/LilyBridge.jpg',
        },
        {
          original: './images/Marilyn.jpg',
          thumbnail: './images/Marilyn.jpg',
        },
        {
          original: './images/MonaLisa.jpg',
          thumbnail: './images/MonaLisa.jpg',
        },
        {
          original: './images/Mountains.jpg',
          thumbnail: './images/Mountains.jpg',
        },
        {
          original: './images/Poppies.jpg',
          thumbnail: './images/Poppies.jpg',
        },
        {
          original: './images/RedLily.jpg',
          thumbnail: './images/RedLily.jpg',
        },
        {
          original: './images/StillLife.jpg',
          thumbnail: './images/StillLife.jpg',
        },
        {
          original: './images/Tulips.jpg',
          thumbnail: './images/Tulips.jpg',
        },
        {
          original: './images/YellowLilys.jpg',
          thumbnail: './images/YellowLilys.jpg',
        }
      ]
    };
  }

  render() {
    return (
      <div className="App">
        <header className="App-header">
          <h2 className="title">Bob's 90th Birthday Website</h2>
          <div className="banner-image-container">
            <img className="banner-image" src="./images/Bob90th.jpg" />
          </div>
        </header>
        <ImageGalleryContainer images={this.state.images}/>
      </div>
    );
  }
}

export default App;
